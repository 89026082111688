import React, { useRef, useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import './ExcelUpload.css';
import { Snackbar, Box, CssBaseline, CircularProgress, Alert, Grid, Paper, Dialog, DialogContent } from '@mui/material';
import NavigationBar from './NavigationBar';
import { useAuth } from '../context/AuthContext';
import { CloudUpload, CalendarToday, GetApp, InfoOutlined } from '@mui/icons-material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00256C',
    },
    secondary: {
      main: '#4A90E2',
    },
  },
});

function ExcelUpload() {
  console.log('ExcelUpload 컴포넌트 렌더링');
  const { 
    isAuthenticated, 
    iscalendarAuth, 
    setCalendarAuth, 
    isExcelUploadAuthorized, 
    setExcelUploadAuthorized, 
    accessToken, 
    setAccessToken, 
    calendarId, 
    setCalendarId, 
    userInfo
  } = useAuth();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [tokenClient, setTokenClient] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가
  const [refreshCalendar, setRefreshCalendar] = useState(false); // 상태 추가

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
      return;
    }
    
    // useLocation 훅을 사용하여 calendar_id, access_token, userId를 상태에 저장
    if (location.state) {
      if (location.state.calendar_id) {
        setCalendarId(location.state.calendar_id);
        console.log('Calendar ID:', location.state.calendar_id);
      }
      if (location.state.access_token) {
        setAccessToken(location.state.access_token);
        console.log('Access Token:', location.state.access_token);
      }
      
    }
 
    // GIS 스크립트 로드
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.onload = initializeGoogleSignIn;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [location]);

  const initializeGoogleSignIn = () => {
    try {
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/calendar',
        callback: (response) => {
          console.log('Access Token:', response.access_token);
          setAccessToken(response.access_token);
          fetchCalendarId(response.access_token);
          setCalendarAuth(true); // 인증 상태 업데이트
        },
      });
      setTokenClient(client);
    } catch (error) {
      console.error('Error initializing Google Sign-In:', error);
    }
  };

  
  const fetchCalendarId = async (token) => {
    try {
      const response = await fetch('https://www.googleapis.com/calendar/v3/users/me/calendarList', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Calendar List:', data);

      if (data.items && data.items.length > 0) {
        const primaryCalendar = data.items.find(calendar => calendar.primary);
        if (primaryCalendar) {
          setCalendarId(primaryCalendar.id);
          console.log('Primary Calendar ID:', primaryCalendar.id);
        } else {
          const firstCalendarId = data.items[0].id;
          setCalendarId(firstCalendarId);
          console.log('First Calendar ID:', firstCalendarId);
        }
      } else {
        console.error('No calendars found.');
      }
    } catch (error) {
      console.error('Error fetching calendar list:', error);
    }
  };

  const handleFileChange = () => {
    if (fileInputRef.current.files.length > 0) {
      // 파일이 선택되었을 때 업로드 시작
      handleUpload(accessToken);
    }
  };

  const handleUpload = async (token) => {
    setIsLoading(true); // 업로드 시작 시 로딩 상태 설정
    const file = fileInputRef.current.files[0];
    
    // 파일 확장자 확인 함수
    const isExcelFile = (file) => {
      const allowedExtensions = ['xlsx', 'xls'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      return allowedExtensions.includes(fileExtension);
    };
    
    if (file && calendarId && token) {
      if (!isExcelFile(file)) {
        setSnackbarMessage('엑셀 파일(.xlsx, .xls)만 업로드 가능합니다.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setIsLoading(false);
        return;
      }
  
      const formData = new FormData();
      formData.append('file', file);
      formData.append('calendar_id', calendarId);
      formData.append('access_token', token);
      formData.append('email', userInfo.email);
  
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
          method: 'POST',
          body: formData,
        });
        if (response.ok) {
          const data = await response.json();
          setSnackbarMessage('업로드 완료되었습니다.');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          fileInputRef.current.value = '';
          setRefreshCalendar(prev => !prev);
        } else {
          throw new Error('파일 업로드 실패');
        }
      } catch (error) {
        setSnackbarMessage(`오류 발생: ${error.message}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } else {
      console.log('파일이 선택되지 않았거나 필요한 정보가 없습니다.');
      setSnackbarMessage('파일이 선택되지 않았거나 필요한 정보가 없습니다.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    setIsLoading(false); // 업로드 완료 후 로딩 상태 해제
  };
  const [manualOpen, setManualOpen] = useState(false);

  const handleManualOpen = () => {
    setManualOpen(true);
  };

  const handleManualClose = () => {
    setManualOpen(false);
  };
  const handleManualDownload = () => {
    const link = document.createElement('a');
    link.href = '/crewcrew_manual.pdf';
    link.download = 'crewcrew_manual.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="excel-upload-container gradient-background">
        <NavigationBar />
        <Box className="content-wrapper">
          <Paper elevation={3} className="main-paper">
            {/* 구글 API 검증 안내 알림 추가 */}
            <Alert 
              severity="info" 
              icon={<InfoOutlined />}
              sx={{ 
                mb: 3, 
                backgroundColor: '#e3f2fd',
                '& .MuiAlert-message': { 
                  color: '#014361',
                  fontSize: '0.95rem'
                }
              }}
            >
              <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                서비스 이용 안내
              </Typography>
              <Typography variant="body2">
                구글 API 보안 검증이 완료되어 신규사용자도 구글 캘린더 연동 기능을 <span style={{ color: '#00a152', fontWeight: 'bold' }}>정상적으로 이용하실 수 있습니다.</span>
              </Typography>
              <Typography variant="body2">
                많은 이용 부탁드립니다.
              </Typography>
             
            </Alert>
            <Typography variant="h4" className="title" gutterBottom>
              스케줄 엑셀 파일 업로드
            </Typography>
            <Box sx={{ mb: 2, p: 2, backgroundColor: 'rgba(74, 144, 226, 0.1)', borderRadius: '4px' }}>
              <Typography variant="subtitle2" gutterBottom>
                사용법 및 주의사항
              </Typography>
              <Typography variant="body2" component="ol" sx={{ pl: 2 }}>
              <li>구글 캘린더 권한을 먼저 허용해주세요.</li>
              <li>엑셀 파일은 Creworld 스케줄화면에서 다운받을 수 있습니다.</li>
              <li>엑셀 파일 업로드 후 약 2~3분 소요됩니다.</li>
              <li>업로드 성공 후 구글 캘린더 동기화에 시간이 걸릴 수 있습니다.</li>
              <li>구글 캘린더에 충분한 시간(5~10분)을 가지고 확인해주세요.</li>
              <li style={{ color: 'red' }}>안드로이드 사용자는 반드시 구글 캘린더 애플리케이션에서 새로고침을 눌러야 변경사항이 반영됩니다!</li>
            </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Button
                variant="contained"
                startIcon={<CalendarToday />}
                onClick={() => {
                  if (tokenClient) {
                    tokenClient.requestAccessToken();
                  }
                }}
                disabled={iscalendarAuth}
                aria-label="구글 캘린더 권한 허용"
                className="action-button primary-button"
              >
                구글 캘린더 권한 허용
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept=".xlsx,.xls"
                onChange={handleFileChange}
              />
              <Button
                variant="contained"
                startIcon={<CloudUpload />}
                onClick={() => fileInputRef.current.click()}
                disabled={!iscalendarAuth || isLoading}
                aria-label="엑셀 파일 업로드"
                className="action-button primary-button"
              >
                엑셀 파일 업로드
              </Button>
              <Button
                variant="contained"
                startIcon={<GetApp />}
                onClick={handleManualDownload}
                aria-label="사용자 메뉴얼 다운로드"
                className="action-button secondary-button"
              >
                사용자 메뉴얼 다운로드
              </Button>
            </Box>
            {isLoading && (
              <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress size={24} sx={{ mr: 1 }} />
                <Typography>구글 캘린더 동기화 진행중...</Typography>
              </Box>
            )}
          </Paper>
        </Box>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      
    </ThemeProvider>
  );
}

export default ExcelUpload;