import React, { useEffect, useRef, useState } from 'react';
import { Box, CssBaseline, Typography, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './GoogleLogin.css';
import NavigationBar from './components/NavigationBar';
import { useAuth } from './context/AuthContext';
import { LogOut } from 'lucide-react';
import './global-styles.css';
import { useNavigate } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4285F4',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const GoogleLogin = () => {
  const googleRef = useRef(null);
  const { 
    isAuthenticated, 
    setIsAuthenticated, 
    userInfo, 
    setUserInfo: setUserInfoAndCheckAdmin, 
    isAdmin,
    logout 
  } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.onload = initializeGoogleSignIn;
    document.body.appendChild(script);

    return () => {
      if (googleRef.current && googleRef.current.accounts) {
        googleRef.current.accounts.id.cancel();
      }
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (googleRef.current && googleRef.current.accounts) {
      if (!isAuthenticated) {
        renderGoogleButton();
        showOneTapPrompt();
      } else {
        googleRef.current.accounts.id.cancel();
      }
    }
  }, [isAuthenticated]);

  const initializeGoogleSignIn = () => {
    try {
      if (typeof window.google !== 'undefined' && window.google.accounts.id) {
        googleRef.current = window.google;
        googleRef.current.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
          callback: handleCredentialResponse,
          auto_select: true,
          cancel_on_tap_outside: false,
        });
        if (!isAuthenticated) {
          renderGoogleButton();
          showOneTapPrompt();
        }
      } else {
        console.error('Google GSI library not loaded.');
        setError('Google Sign-In is not available at the moment.');
      }
    } catch (error) {
      console.error('Error initializing Google Sign-In:', error);
      setError('Failed to initialize Google Sign-In.');
    }
  };

  const showOneTapPrompt = () => {
    if (googleRef.current && googleRef.current.accounts && !isAuthenticated) {
      googleRef.current.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          console.log('One-tap prompt was not displayed or was skipped');
        }
      });
    }
  };

  const renderGoogleButton = () => {
    if (googleRef.current && googleRef.current.accounts && !isAuthenticated) {
      googleRef.current.accounts.id.renderButton(
        document.getElementById('google-login-button'),
        {
          type: 'standard',
          size: 'large',
          theme: 'filled_blue',
          text: 'continue_with',
          shape: 'pill',
          logo_alignment: 'left',
          width: '300',
        }
      );
    }
  };

  const handleCredentialResponse = (response) => {
    if (response.credential) {
      setIsLoading(true);
      setError(null);
      sendIdTokenToServer(response.credential);
    } else {
      console.error('Google Sign-In failed:', response.error);
      setError('Failed to sign in with Google. Please try again.');
    }
  };

  const sendIdTokenToServer = async (idToken) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/google/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id_token: idToken }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setIsAuthenticated(true);
      setUserInfoAndCheckAdmin(data.user_info);
    } catch (error) {
      console.error('Error sending ID token to backend:', error);
      setError('Failed to authenticate. Please try again.');
      setIsAuthenticated(false);
      setUserInfoAndCheckAdmin(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    logout();
    setError(null);
    if (googleRef.current && googleRef.current.accounts) {
      googleRef.current.accounts.id.disableAutoSelect();
    }
    setIsAuthenticated(false);
    setUserInfoAndCheckAdmin(null);
    navigate('/');
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <NavigationBar />
        <CssBaseline />
        <Box className="gradient-background" sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box className="content-container">
            <Typography component="h2" variant="h4" sx={{ marginBottom: 2 }}>
              Welcome to CREWCREW
            </Typography>
            {error && (
              <Typography color="error" sx={{ marginBottom: 2 }}>
                {error}
              </Typography>
            )}
            {!isAuthenticated && !isLoading && (
              <>
                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                  Sign in to access all features
                </Typography>
                <div id="google-login-button"></div>
              </>
            )}
            {isLoading && (
              <CircularProgress sx={{ marginTop: 2 }} />
            )}
            {isAuthenticated && userInfo && (
              <>
                <Typography variant="h5" sx={{ marginBottom: 1 }}>
                  환영합니다, {userInfo.name}님!
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                  로그인이 완료되었습니다.
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                  계정 유형: {isAdmin ? '관리자' : '일반 사용자'}
                </Typography>
                <button className="primary-button" onClick={handleLogout}>
                  <LogOut size={20} style={{ marginRight: '8px' }} />
                  로그아웃
                </button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default GoogleLogin;