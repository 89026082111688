// src/components/LanguageToggle.js
import React from 'react';
import { useLanguage } from '../context/LanguageContext';
import './LanguageToggle.css';

const LanguageToggle = () => {
    const { language, setLanguage } = useLanguage();

    return (
        <div className="language-toggle-container">
            <div className="language-toggle-wrapper">
                <button 
                    className={`language-btn ${language === 'ko' ? 'active' : ''}`}
                    onClick={() => setLanguage('ko')}
                >
                    한국어
                </button>
                <button 
                    className={`language-btn ${language === 'en' ? 'active' : ''}`}
                    onClick={() => setLanguage('en')}
                >
                    English
                </button>
            </div>
        </div>
    );
};

export default LanguageToggle;