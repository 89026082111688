// src/context/LanguageContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('ko');
    
    const translations = {
        ko: {
            navigation: {
                home: '홈',
                schedule: '스케줄',
                contact: '문의하기',
                vision: '비전',
                legal: '법적 고지',
                terms: '이용약관',
                privacy: '개인정보처리방침',
                logout: '로그아웃',
                login: '로그인'
            },
            landing: {
                hero: {
                    title: '아시아나 승무원을 위한\n스마트한 스케줄 관리',
                    subtitle: '엑셀 파일 하나로 구글 캘린더와 완벽한 동기화',
                    button: 'Google로 시작하기'
                },
                features: {
                    title: '주요 기능',
                    cards: [
                        {
                            title: '간편한 업로드',
                            description: '엑셀 파일을 드래그 앤 드롭하거나 클릭 한 번으로 업로드하세요'
                        },
                        {
                            title: '자동 동기화',
                            description: '업로드된 스케줄이 구글 캘린더에 자동으로 동기화됩니다'
                        },
                        {
                            title: '실시간 업데이트',
                            description: '스케줄 변경 시 신속하게 캘린더에 반영됩니다'
                        }
                    ]
                },
                oauth: {
                    title: 'Google Calendar 권한 안내',
                    calendar: {
                        title: '캘린더 읽기/쓰기',
                        description: '스케줄을 구글 캘린더에 자동으로 동기화하기 위해 필요합니다'
                    },
                    profile: {
                        title: '기본 프로필 정보',
                        description: '서비스 이용을 위한 계정 식별용으로만 사용됩니다'
                    }
                },
                security: {
                    title: '데이터 보안 및 개인정보 보호',
                    encryption: {
                        title: '데이터 암호화',
                        description: '모든 데이터는 업계 표준 암호화 방식으로 안전하게 보호됩니다'
                    },
                    access: {
                        title: '제한된 데이터 접근',
                        description: '승인된 캘린더 이벤트 관리 외 다른 목적으로 데이터를 사용하지 않습니다'
                    }
                },
                footer: {
                    company: '아시아나 승무원을 위한 스케줄 관리 서비스',
                    importantLinks: '중요 링크',
                    support: '고객지원',
                    email: '이메일'
                }
            }
        },
        en: {
            navigation: {
                home: 'Home',
                schedule: 'Schedule',
                contact: 'Contact us',
                vision: 'Vision',
                legal: 'Legal',
                terms: 'Terms of Service',
                privacy: 'Privacy Policy',
                logout: 'Logout',
                login: 'Login'
            },
            landing: {
                hero: {
                    title: 'Smart Schedule Management\nfor Asiana Flight Attendants',
                    subtitle: 'Perfect synchronization with Google Calendar using a single Excel file',
                    button: 'Get Started with Google'
                },
                features: {
                    title: 'Key Features',
                    cards: [
                        {
                            title: 'Easy Upload',
                            description: 'Upload your schedule with drag & drop or single click'
                        },
                        {
                            title: 'Auto Sync',
                            description: 'Automatically syncs uploaded schedule to Google Calendar'
                        },
                        {
                            title: 'Real-time Updates',
                            description: 'Schedule changes are reflected promptly in your calendar'
                        }
                    ]
                },
                oauth: {
                    title: 'Google Calendar Permissions',
                    calendar: {
                        title: 'Calendar Read/Write',
                        description: 'Required to automatically sync your schedule to Google Calendar'
                    },
                    profile: {
                        title: 'Basic Profile Info',
                        description: 'Used only for account identification for service use'
                    }
                },
                security: {
                    title: 'Data Security & Privacy',
                    encryption: {
                        title: 'Data Encryption',
                        description: 'All data is securely protected using industry-standard encryption'
                    },
                    access: {
                        title: 'Limited Data Access',
                        description: 'Data is used solely for managing authorized calendar events'
                    }
                },
                footer: {
                    company: 'Schedule Management Service for Asiana Crew',
                    importantLinks: 'Important Links',
                    support: 'Support',
                    email: 'Email'
                }
            }
        }
    };

    const t = useCallback((section, key) => {
        const sections = section.split('.');
        let result = translations[language];
        for (const s of sections) {
            result = result?.[s];
            if (!result) {
                result = translations['ko'];
                for (const s2 of sections) {
                    result = result?.[s2];
                }
                break;
            }
        }
        return result || key;
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, setLanguage, t, translations }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};