import React from 'react';
import { Typography, Box, Grid, Card, CardContent, CardMedia, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import { useAuth } from '../context/AuthContext';
import './ScheduleMain.css';

// 이미지 URL을 실제 이미지 경로로 교체해야 합니다
const excelImage = './excelUpload.png';
const imageUploadImage = './imageUpload.png';
const creworldImage ='./creworld.png';

function ScheduleMain() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const handleExcelUpload = () => {
    navigate('/ExcelUpload');
  };

  const handleScheduleImage = () => {
    navigate('/ScheduleImage');
  };

  const handleCreworldImport = () => {
    setSnackbarOpen(true);
  };
  const handleScheduleManage = () => {
    navigate('/ScheduleCalendar');
  };
  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="route-component gradient-background schedule-main">
      <NavigationBar />
      <Box sx={{ 
        p: 3, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        minHeight: 'calc(100vh - 64px)', // NavigationBar 높이를 고려
        paddingTop: '64px', // NavigationBar 높이만큼 상단 패딩 추가
      }}>
       
        <Grid container spacing={4} sx={{ maxWidth: 1200, margin: 'auto', justifyContent: 'center' }}>
          <Grid item xs={12} md={4}>
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column', 
                transition: '0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                },
                cursor: 'pointer'
              }}
              onClick={handleExcelUpload}
            >
              <CardMedia
                component="img"
                height="200"
                image={excelImage}
                alt="Excel Upload"
              />
              <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Typography gutterBottom variant="h5" component="div">
                  엑셀 업로드
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  엑셀 파일을 업로드하여 스케줄을 등록하세요.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
          {/* <Grid item xs={12} md={4}>
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                transition: '0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                },
                cursor: 'pointer'
              }}
              onClick={handleScheduleImage}
            >
              <CardMedia
                component="img"
                height="200"
                image={imageUploadImage}
                alt="Image Upload"
              />
              <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Typography gutterBottom variant="h5" component="div">
                  이미지 업로드
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  스케줄 캡쳐 이미지를 업로드하여 스케줄을 등록하세요.
                </Typography>
              </CardContent>
            </Card>
          </Grid> */}
          
          <Grid item xs={12} md={4}>
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                transition: '0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                },
                cursor: 'pointer'
              }}
              onClick={handleCreworldImport}
            >
              <CardMedia
                component="img"
                height="200"
                image={creworldImage}
                alt="Creworld Import"
              />
              <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Typography gutterBottom variant="h5" component="div">
                  크루월드에서 가져오기
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  VPN 접속 후 크루월드 시스템에서 스케줄을 자동으로 가져옵니다.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
        </Grid>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="info" sx={{ width: '100%' }}>
          APP Store APP에서 실행될 예정입니다.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ScheduleMain;