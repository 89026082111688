import React from 'react';
import { Box, CssBaseline, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavigationBar from './NavigationBar';
import './PrivacyPolicy.css';

const theme = createTheme();

const PrivacyPolicy = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <NavigationBar />
        <CssBaseline />
        <div className="privacy-policy-container gradient-background">
          <div className="privacy-policy-content">
            <Typography variant="h3" component="h1" className="privacy-policy-title">
              크루크루(crewcrew) 개인정보 처리방침
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              현행 시행 일자: 2024년 11월 07일
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              크루크루(이하 "회사")는 이용자의 개인정보를 중요시하며, 「개인정보 보호법」 등 관련 법령을 준수하고 있습니다. 회사는 개인정보처리방침을 통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
            </Typography>
            
            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              1. 수집하는 개인정보 항목 및 수집방법
            </Typography>
            
            <Typography variant="h6" component="h3" className="privacy-policy-subtitle">
              1) Google 계정을 통해 수집하는 정보
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>Google 계정 고유 식별자(user_id)</li>
              <li>이메일 주소</li>
              <li>이름 (성과 이름)</li>
              <li>성(family_name)</li>
              <li>이름(given_name)</li>
              <li>프로필 사진 URL</li>
              <li>관리자 권한 여부</li>
              <li>Google Calendar 접근 권한 및 캘린더 ID</li>
            </ul>
            
            <Typography variant="h6" component="h3" className="privacy-policy-subtitle">
              2) 서비스 이용 과정에서 수집하는 정보
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>업로드된 스케줄 파일 (바이너리 형식)</li>
              <li>파일명 및 업로드 시간</li>
              <li>서비스 이용 기록</li>
              <li>접속 로그</li>
              <li>IP 주소</li>
              <li>접속 시간</li>
            </ul>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              2. 개인정보의 수집 및 이용목적
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>회원 식별 및 로그인 처리</li>
              <li>Google Calendar 연동을 통한 일정 관리 서비스 제공</li>
              <li>스케줄 파일 처리 및 Google Calendar 동기화</li>
              <li>서비스 이용에 대한 통계 분석</li>
              <li>시스템 관리 및 운영</li>
              <li>불법적인 사용 및 부정 이용 방지</li>
              <li>문의사항 또는 불만처리 등 민원처리</li>
              <li>신규 서비스 개발 및 서비스 개선</li>
            </ul>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              3. Google 사용자 데이터 처리 방침
            </Typography>
            
            <Typography variant="h6" component="h3" className="privacy-policy-subtitle">
              1) 데이터 수집 및 사용
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>회사는 Google OAuth 2.0 인증을 통해 사용자 데이터를 수집합니다.</li>
              <li>수집된 데이터는 서비스 제공을 위한 최소한의 범위 내에서만 사용됩니다.</li>
              <li>Google Calendar API는 사용자의 일정 관리 기능 제공을 위해서만 사용됩니다.</li>
            </ul>

            <Typography variant="h6" component="h3" className="privacy-policy-subtitle">
              2) 데이터 공유 및 제3자 제공
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>회사는 사용자가 업로드한 스케줄 데이터를 사용자의 Google Calendar와 공유합니다.</li>
              <li>이 외의 Google 사용자 데이터는 제3자와 공유하지 않습니다.</li>
              <li>법적 요구사항이 있는 경우를 제외하고, 사용자의 명시적 동의 없이 외부에 제공하지 않습니다.</li>
            </ul>

            <Typography variant="h6" component="h3" className="privacy-policy-subtitle">
              3) 데이터 보안
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>모든 데이터는 암호화되어 저장됩니다.</li>
              <li>데이터 전송 시 보안 프로토콜(HTTPS)을 사용합니다.</li>
              <li>Google API 사용 시 OAuth 2.0 보안 표준을 준수합니다.</li>
            </ul>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              4. 개인정보의 보유 및 이용 기간
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 이용자의 개인정보를 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>회원 정보: 회원 탈퇴 시까지</li>
              <li>스케줄 파일: Google Calendar 동기화 완료 후 즉시 삭제</li>
              <li>서비스 이용 기록: 3개월</li>
              <li>접속 로그 및 IP: 3개월</li>
            </ul>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              5. 개인정보의 파기절차 및 방법
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>전자적 파일 형태로 저장된 개인정보는 복구 불가능한 방법으로 영구 삭제합니다.</li>
              <li>업로드된 스케줄 파일은 Google Calendar 동기화 완료 후 즉시 자동으로 삭제됩니다.</li>
              <li>로그 파일은 정해진 보유기간 종료 후 자동으로 삭제됩니다.</li>
            </ul>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              6. 개인정보의 안전성 확보 조치
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다:
            </Typography>
            
            <Typography variant="h6" component="h3" className="privacy-policy-subtitle">
              1) 관리적 조치
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>내부관리계획 수립 및 시행</li>
              <li>개인정보 취급 직원 최소화</li>
              <li>정기적인 직원 교육</li>
            </ul>

            <Typography variant="h6" component="h3" className="privacy-policy-subtitle">
              2) 기술적 조치
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>개인정보 암호화 저장 및 전송</li>
              <li>접근권한 관리 및 접근통제</li>
              <li>클라우드 서비스 제공자의 보안 기능 활용</li>
              <li>데이터베이스 접근 통제</li>
            </ul>

            <Typography variant="h6" component="h3" className="privacy-policy-subtitle">
              3) 물리적 조치
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>클라우드 서비스 제공자의 물리적 보안 조치 활용</li>
              <li>비인가자의 접근 통제</li>
            </ul>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              7. 이용자 및 법정대리인의 권리와 그 행사방법
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>Google 계정 연동 해제</li>
              <li>개인정보 열람 요구</li>
              <li>오류 정정 요구</li>
              <li>삭제 요구</li>
              <li>처리정지 요구</li>
              <li>동의철회</li>
            </ul>

            <Typography variant="body1" className="privacy-policy-text">
              위 권리 행사는 회사에 대해 서면, 전화, 전자우편 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
            </Typography>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 세션 ID를 사용합니다. 이는 로그인 상태 유지 등 서비스 이용에 필수적인 기능을 제공하기 위해 사용되며, 보안성 강화를 위해 세션은 일정 시간 후 자동으로 만료됩니다.
            </Typography>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              9. 개인정보 보호책임자 및 담당부서
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
            </Typography>

            <ul className="privacy-policy-list">
              <li>개인정보 보호책임자</li>
              <li>성명: 양선모</li>
              <li>직책: 책임</li>
              <li>연락처: crewcreww.world@gmail.com</li>
            </ul>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              10. 권익침해 구제방법
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              이용자는 개인정보침해로 인한 구제를 받기 위하여 다음 기관에 분쟁해결이나 상담 등을 신청할 수 있습니다.
            </Typography>

            <ul className="privacy-policy-list">
              <li>개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)</li>
              <li>개인정보분쟁조정위원회 (www.kopico.go.kr / 1833-6972)</li>
              <li>대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)</li>
              <li>경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182)</li>
            </ul>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              11. 개인정보처리방침의 변경
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              이 개인정보처리방침은 2024년 11월 07일부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이수 정될 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 고지합니다.
           </Typography>

           <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
             12. Google API 서비스 관련 고지사항
           </Typography>
           
           <Typography variant="body1" className="privacy-policy-text">
             회사의 서비스는 Google API 서비스를 사용하며, 다음과 같은 정책을 따릅니다:
           </Typography>

           <ul className="privacy-policy-list">
             <li>Google API 서비스 약관을 준수합니다.</li>
             <li>Google Calendar API를 통해 접근하는 데이터는 일정 관리 목적으로만 사용됩니다.</li>
             <li>사용자는 Google 계정 설정에서 언제든지 앱 접근 권한을 취소할 수 있습니다.</li>
             <li>Google의 Limited Use 요구사항을 준수합니다.</li>
           </ul>

           <Typography variant="h6" component="h3" className="privacy-policy-subtitle">
             1) Google 사용자 데이터의 제한적 사용
           </Typography>

           <ul className="privacy-policy-list">
             <li>Google API에서 받은 데이터는 서비스 제공에 필요한 최소한의 범위에서만 사용됩니다.</li>
             <li>사용자의 Google 데이터는 사용자의 이익을 위해서만 사용되며, 지속적으로 보호됩니다.</li>
             <li>Google API 서비스를 통해 받은 데이터는 광고 목적으로 사용되지 않습니다.</li>
             <li>법률이 요구하는 경우를 제외하고 Google API 데이터를 제3자에게 판매하거나 공유하지 않습니다.</li>
           </ul>

           <Typography variant="h6" component="h3" className="privacy-policy-subtitle">
             2) 데이터 전송 보안
           </Typography>

           <ul className="privacy-policy-list">
             <li>모든 Google API 데이터는 안전한 HTTPS 연결을 통해 전송됩니다.</li>
             <li>데이터 전송 시 최신 보안 프로토콜과 암호화 방식을 사용합니다.</li>
             <li>안전한 인증 방식을 사용하여 데이터 접근을 제어합니다.</li>
           </ul>

           <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
             13. 기타
           </Typography>
           
           <Typography variant="body1" className="privacy-policy-text">
             개인정보처리방침에 대해 궁금하신 사항이 있거나 개인정보처리방침과 관련하여 불만이나 의견이 있으신 경우 개인정보 보호책임자에게 연락 주시면 신속하고 성실한 답변을 드리도록 하겠습니다.
           </Typography>
         </div>
       </div>
     </Box>
   </ThemeProvider>
 );
};

export default PrivacyPolicy;