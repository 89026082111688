import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import './NavigationBar.css';

const NavigationBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLegalDropdownOpen, setIsLegalDropdownOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { isAuthenticated, logout } = useAuth();
    const { language, setLanguage, t } = useLanguage();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleLegalDropdown = () => {
        setIsLegalDropdownOpen(!isLegalDropdownOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
        setIsLegalDropdownOpen(false);
    };

    const handleHomeClick = () => {
        if (isAuthenticated) {
            navigate('/login');
        } else {
            navigate('/');
        }
        closeMenu();
    };

    const handleAuthAction = () => {
        if (isAuthenticated) {
            logout();
            navigate('/');
        } else {
            navigate('/login');
        }
        closeMenu();
    };

    useEffect(() => {
        closeMenu();
    }, [location]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isMenuOpen && 
                !event.target.closest('.navigation-bar-menu') && 
                !event.target.closest('.navigation-bar-menu-icon')) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isMenuOpen]);

    return (
        <nav className="navigation-bar">
            <div className="navigation-bar-content">
                <div className="navigation-bar-logo" onClick={handleHomeClick}>CREWCREW</div>
                <div className="navigation-bar-right">
                    {/* 언어 선택 추가 */}
                    

                    <button 
                        className="navigation-bar-menu-icon" 
                        onClick={toggleMenu}
                        aria-label="Toggle menu"
                    >
                        {isMenuOpen ? '✕' : '☰'}
                    </button>
                </div>

                <div className={`navigation-bar-menu ${isMenuOpen ? 'active' : ''}`}>
                    <div className="navigation-bar-menu-item" onClick={handleHomeClick}>
                        {t('navigation.home')}
                    </div>
                    {isAuthenticated && (
                        <>
                            <Link to="/ScheduleMain" className="navigation-bar-menu-item" onClick={closeMenu}>
                                {t('navigation.schedule')}
                            </Link>
                            <Link to="/FeedBack" className="navigation-bar-menu-item" onClick={closeMenu}>
                                {t('navigation.contact')}
                            </Link>
                        </>
                    )}
                    <Link to="/AppVision" className="navigation-bar-menu-item" onClick={closeMenu}>
                        {t('navigation.vision')}
                    </Link>
                    
                    <div 
                        className="navigation-bar-menu-item dropdown"
                        onMouseEnter={() => setIsLegalDropdownOpen(true)}
                        onMouseLeave={() => setIsLegalDropdownOpen(false)}
                    >
                        <span>{t('navigation.legal')}</span>
                        <div className={`dropdown-content ${isLegalDropdownOpen ? 'show' : ''}`}>
                            <Link to="/terms" onClick={closeMenu}>
                                {t('navigation.terms')}
                            </Link>
                            <Link to="/PrivacyPolicy" onClick={closeMenu}>
                                {t('navigation.privacy')}
                            </Link>
                        </div>
                    </div>
                    
                    {/* 모바일에서만 보이는 언어 선택 */}
                    
                    <button 
                        className={`nav-auth-button ${isAuthenticated ? 'logout' : 'login'}`}
                        onClick={handleAuthAction}
                    >
                        {isAuthenticated ? t('navigation.logout') : t('navigation.login')}
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default NavigationBar;