import React from 'react';
import { Calendar, Upload, Clock, Shield, Globe, Lock } from 'lucide-react';
import NavigationBar from './components/NavigationBar';
import { useLanguage } from './context/LanguageContext';
import './LandingPage.css';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
    const navigate = useNavigate();
    const { language, setLanguage, t } = useLanguage();

    const handleLoginClick = () => {
        navigate('/login');
    };

    return (
        <div className="landing-container">
            <NavigationBar />
            
            {/* Language Toggle */}
           

            {/* Hero Section */}
            <section className="hero-section">
                <div className="hero-content">
                    <h1 className="hero-title">
                        {t('landing.hero.title')}
                    </h1>
                    <p className="hero-subtitle">
                        {t('landing.hero.subtitle')}
                    </p>
                    <button className="google-login-button" onClick={handleLoginClick}>
                        <div className="google-login-button-inner">
                            <GoogleIcon />
                            <span className="google-button-text">
                                {t('landing.hero.button')}
                            </span>
                        </div>
                    </button>
                </div>
            </section>

            {/* Features Section */}
            <section className="features-section">
                <div className="features-content">
                    <h2 className="section-title">{t('landing.features.title')}</h2>
                    <div className="features-grid">
                        <FeatureCard
                            icon={<Upload />}
                            title={t('landing.features.cards.0.title')}
                            description={t('landing.features.cards.0.description')}
                        />
                        <FeatureCard
                            icon={<Calendar />}
                            title={t('landing.features.cards.1.title')}
                            description={t('landing.features.cards.1.description')}
                        />
                        <FeatureCard
                            icon={<Clock />}
                            title={t('landing.features.cards.2.title')}
                            description={t('landing.features.cards.2.description')}
                        />
                    </div>
                </div>
            </section>

            {/* OAuth Permissions Section */}
            <section className="oauth-section">
                <div className="oauth-content">
                    <h2 className="section-title">
                        {t('landing.oauth.title')}
                    </h2>
                    <div className="oauth-grid">
                        <OAuthCard
                            icon={<Calendar />}
                            title={t('landing.oauth.calendar.title')}
                            description={t('landing.oauth.calendar.description')}
                        />
                        <OAuthCard
                            icon={<Lock />}
                            title={t('landing.oauth.profile.title')}
                            description={t('landing.oauth.profile.description')}
                        />
                    </div>
                </div>
            </section>

            {/* Security Section */}
            <section className="security-section">
                <div className="security-content">
                    <h2 className="section-title">
                        {t('landing.security.title')}
                    </h2>
                    <div className="security-grid">
                        <SecurityCard
                            icon={<Shield />}
                            title={t('landing.security.encryption.title')}
                            description={t('landing.security.encryption.description')}
                        />
                        <SecurityCard
                            icon={<Globe />}
                            title={t('landing.security.access.title')}
                            description={t('landing.security.access.description')}
                        />
                    </div>
                </div>
            </section>

            {/* Footer */}
            <footer className="landing-footer">
                <div className="footer-content">
                    <div className="footer-section">
                        <h3>CrewCrew</h3>
                        <p>{t('landing.footer.company')}</p>
                    </div>
                    <div className="footer-section">
                        <h3>{t('landing.footer.importantLinks')}</h3>
                        <ul>
                            <li>
                                <a href="/terms" target="_blank" rel="noopener noreferrer">
                                    {t('navigation.terms')}
                                </a>
                            </li>
                            <li>
                                <a href="/privacyPolicy" target="_blank" rel="noopener noreferrer">
                                    {t('navigation.privacy')}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>{t('landing.footer.support')}</h3>
                        <ul>
                            <li>{t('landing.footer.email')}: crewcrew.world@gmail.com</li>
                        </ul>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>© 2024 CrewCrew. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

const FeatureCard = ({ icon, title, description }) => (
    <div className="feature-card">
        <div className="icon">
            {React.cloneElement(icon, { size: 40 })}
        </div>
        <h3>{title}</h3>
        <p>{description}</p>
    </div>
);

const OAuthCard = ({ icon, title, description }) => (
    <div className="oauth-card">
        <div className="icon">
            {React.cloneElement(icon, { size: 32 })}
        </div>
        <h3>{title}</h3>
        <p>{description}</p>
    </div>
);

const SecurityCard = ({ icon, title, description }) => (
    <div className="security-card">
        <div className="icon">
            {React.cloneElement(icon, { size: 32 })}
        </div>
        <h3>{title}</h3>
        <p>{description}</p>
    </div>
);

const GoogleIcon = () => (
    <svg className="google-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
        <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
        <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
        <path fill="none" d="M0 0h48v48H0z"/>
    </svg>
);

export default LandingPage;